.gpt3__feature {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: space-around;
    align-content: stretch;
    flex-direction: row;
    padding: 1rem 0rem;
    
    
    .title {
        flex: 1;
        display: flex;
        flex-direction: column;
        max-width: 180px;
        width: 100%;
        margin-right: 2rem;
        margin-top: 0.41rem;
        
        justify-content: flex-start;

        h1 {
            font-family: var(--font-family);
            
            font-weight: 600;
            font-size: 16px;
            
            line-height: 24px;
            letter-spacing: 0rem;
            color: rgb(198, 203, 245);

        }

        div {
            width: 100%;
            height: 3px;
            background: var(--gradient-bar);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            margin-bottom: 0.25rem;
        }

        
    }

    .text {
        margin:0;
        flex: 1;
        max-width: 390px;
        display: flex;

        p {
            font-family: var(--font-family);
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: var(--color-text);
            text-align: justify;
        }
    }
}



@media screen and (max-width: 900px) {

    .title {
        margin-right: 0;
    }
    
}












@media screen and (max-width: 500px) {
    .gpt3__feature {
        display: flex;
        flex-direction: column;
        margin: 0;
        

        .title {
            
            width: 100%;
            margin-right: 0;
            max-width: 360px;
            margin-bottom: 2rem;
            
                h1{
                    width: 100%;
                    text-align: left;
                }
               
            
        }
        .text{

            margin: 0;
            p {
                margin: 0;
            }
        }
      
    }

}