.gpt3__article {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    background: #1f5aff21;
    border-radius: 3px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.795);
    border: 2px solid rgba(12, 8, 43, 0.795);
    justify-content: flex-start;
    padding: 0;
  
    
    

    .image {
        width: 100%;
        height: auto;
        background:transparent;
        border-radius: 3px;

        img{
            width: 100%;
            height: auto;
            -webkit-border-top-left-radius: 3px;
            -webkit-border-top-right-radius: 3px;
            -moz-border-radius-topleft: 3px;
            -moz-border-radius-topright: 3px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border-bottom: groove 5px rgba(24, 62, 233, 0.425);
        }
        
        
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem 1.5rem;
        height: 100%;
        -webkit-border-bottom-left-radius: 3px;
        -webkit-border-bottom-right-radius: 3px;
        -moz-border-radius-bottomleft: 3px;
        -moz-border-radius-bottomright: 3px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;


       

        h3 {
            font-family: var(--font-family);
            font-weight: 800;
            font-size: 13px;
            line-height: 30px;
            text-align: center;
            margin-bottom: 3rem;
            cursor: pointer;
            justify-self: center;
        }

        p:last-child {
            cursor: pointer;
        }

        .item {
            display: flex;
            flex-direction: row;        
            width: 100%;
            margin:0.51rem;
            align-items: flex-start;

            
         
            p {
                
                font-family: var(--font-family);
                font-weight: 600;
                font-size: 13px;
                text-align: justify;
                color: #FFFFFF;
                
            }

            .icon {
                align-self: center;
                flex-shrink: 0;
                margin-right:1rem;
                font-size: 20px;
                color:rgb(118, 138, 240);
                font-weight: bold;
                border: 1.5px solid rgba(10, 130, 243, 0.404);
                border-radius: 4px;
                background-color: rgb(0, 0, 0);
            }
          
          
        }
     
        
    }


    
    
}


@media screen and (max-width:1400px) {
    .gpt3__article {
        .content {
            .content-title {
                display: flex;
                width: 100%;
              
                padding: 0;

                 h3 {
                    margin-bottom: 2rem;
                    font-weight: 800;
                    font-size: 12px;
                    line-height: 30px;
                 }
            }

            .item {
                p {
                    font-weight: 500;
                    font-size: 12px;
                }
            }
        }

       
    }
}

@media screen and (max-width:1200px) {
    .gpt3__article {
        .content {
            .content-title {
          
                 h3 {
                    margin-bottom: 1rem;
                 }
            }

            .item {
                margin: 1rem 0 0 0;
            }
        }

       
    }
}


@media screen and (max-width:990px) {
    .gpt3__article {
        display: flex;
        height: 25rem;
        
        align-content: flex-start;
        

        .image {
            display: flex;
            width: auto;
            height: 60%;
            img {
                object-fit: cover;
            }


        }
        .content {
            
            display: flex;
            height:40%;
            padding: 2rem 2rem ;
            
            .content-title {
               
                 h3 {
                    margin:0;
                 }
            }

            .item {
                margin-top: 0.21rem;
               
                p {
                    font-weight: 500;
                    font-size: 11px;
                }
            }
           
        }
       
    }
}



@media screen and (max-width:650px) {
    .gpt3__article {
        width: 60%;
        margin-bottom: 4rem;
        

        .image{
            height:50%;
        }

        .content {
            padding: 1rem 1rem ;
            height:40%;
          

            .item {
                margin-top: 0.8rem;
                
               
                p {
                    font-weight: 500;
                    font-size: 12px;
                }
            }
          
        }
       
    }
}

@media screen and (max-width:550px) {
    .gpt3__article {
        width: 70%;
        



        .content {
        
          

            .item {
               
                
               
                p {
                    
                }
            }
          
        }
       
    }
}


@media screen and (max-width:450px) {
    .gpt3__article {
        width: 80%;
        



        .content {
        
          

            .item {
               
                
               
                p {
                    
                }
            }
          
        }
       
    }
}