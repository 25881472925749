@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --font-family: "Manrope", sans-serif;

  --gradient-text: linear-gradient(89.97deg, #ffdc8f 1.84%, #fa5f44 102.67%);
  --gradient-text2: linear-gradient(89.97deg, #8fa9ff 1.84%, #f444fa 102.67%);
  --gradient-text3: linear-gradient(89.97deg, #ff8f8f 1.84%, #fa6e44 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa60 -13.86%,rgba(14, 31, 185, 0.253) 99.55%);




  --color-bg: #040c18;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #81afdd;
  --color-subtext: #ff8a71;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;

 
}