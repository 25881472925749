.gpt3__header {
    display: flex;
    flex-direction: row;
    background-image: url(../../assets/backlogo.jpg);
    background-size: cover;
    max-width: 100%;
    width: 100%;




    height: 800px;

    .content {
        flex: 2;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 2rem;
        margin-top: 3rem;

        .quality {
            display: flex;
            margin-top: 0.8rem;
            margin-bottom: 2px;
            .quality2 {
                display: flex;
                width: 100%;
                flex-shrink: 1;
                
                h3 {
                
                    font-family: var(--font-family);
                    font-weight: 500;
                    font-size: 40px;
                    line-height: 28px;
                    color: rgba(225, 252, 235, 0.404);
                    text-transform: uppercase;
                    letter-spacing: 0.4rem;
                    margin-top: 1rem;
    
                }

            }

         

            
        }

        .quest {
            margin-top: 5rem;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: 100%;
            height: 30%;

            h1 {
                font-family: var(--font-family);
                font-weight: 800;
                font-size: 62px;
                line-height: 75px;
                margin-right: 2rem;
                text-shadow: 0 2px 3px rgba(1, 15, 6, 0.137);
            }
        }
    }    
}

@media screen and (max-width:1200px) {
    .gpt3__header {
        .content {
            .quality {
                .quality2 {
                    h3 {
                        font-size: 36px;
                        line-height: 26.2px;
                        letter-spacing: 0.36rem;
                        margin-top: 0.9rem;
                    }
                }
            }
        }
    }

}

@media screen and (max-width:1000px) {
       
           .gpt3__header {
            .content {
                .quality {
                    .quality2 {
                        h3 {
                            font-size: 36px;
                            line-height: 26.2px;
                            letter-spacing: 0.36rem;
                            margin-top: 0.9rem;
                        }
                    }
                }
            }
            .quest {
                .quest2 {
                    h1 {                
                       
                        font-size: 55.8px;
                        line-height: 67.5px;
                        margin-right: 1.8rem;
                    }

                }
            }
            
           } 
        }

        @media screen and (max-width:550px) {
       
            .gpt3__header {
             .content {
                 .quality {
                     .quality2 {
                         h3 {
         
                      
                             font-size: 30px;
                             line-height: 22px;
                             letter-spacing: 0.3rem;
                             margin-top: 0.8rem;
                         }
                     }
                 }
             }
             .quest {
                 .quest2 {
                     h1 {                
                         font-weight: 700;
                         font-size: 48px;
                         line-height: 60px;
                         margin-right: 0.8rem;
                     }
 
                 }
             }
             
            } 
         }


         @media screen and (max-width:450px) {
       
            .gpt3__header {
             .content {
                 .quality {
                     .quality2 {
                         h3 {
         
                      
                             font-size: 25px;
                             line-height: 25px;
                             letter-spacing: 0.25rem;
                             margin-top: 0.7rem;
                         }
                     }
                 }
             }
             .quest {
                 .quest2 {
                     h1 {                
                         font-weight: 700;
                         font-size: 38px;
                         line-height: 50px;
                         margin-right: 0.8rem;
                     }
 
                 }
             }
             
            } 
         }