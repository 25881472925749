.gpt3__features {
  
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    align-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;

    .heading {
      
        display: flex;
        justify-content: center;
        align-items: flex-start;        
        flex-direction: column;
        padding: 2rem;        
        flex:1;        
        flex-grow: grow;
        text-align: left;

        h1 {
            font-size: 34px;
            line-height: 45px;
            font-weight: 800;
            font-family: var(--font-family);           
        }

        p {
            color: var(--color-subtext);
            font-size: 16px;
            line-height: 30px;
            font-weight: 500;
            font-family: var(--font-family);
            align-self: flex-end;
            margin-top: 2rem;
            margin-right: 4rem;
        }
    }

    .container {
        display: flex;
        flex:1.2;
        justify-content: flex-start;
        align-content: flex-start;
        flex-direction: column;
        margin-right:2rem;
    }
}










@media screen and (max-width: 900px) {
    .gpt3__features {
        flex-direction: column;
        justify-content: center;
        .heading {
            
            width:90%;
        }
        .container {
            margin-right:0;
            width: 85%;
        }
        .text {
            width: 90%;
        }
    }

    
}

@media screen and (max-width: 550px) {
    .gpt3__features {
        container {
          
        }
        
    }
}

@media screen and (max-width: 550px) {
    .gpt3__features-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }
}