.gpt3__blog {
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
  padding: 2rem;

  .heading {
    width: 100%;
    text-align: left;
    margin-bottom: 5rem;

    h1 {
      font-size: 50px;
      line-height: 75px;
      font-family: var(--font-family);
      font-weight: 700;
      color: rgba(200, 215, 243, 0.8);
    }

    span {
      background: var(--gradient-text);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

  }

  .container {
    display: flex;
    flex-direction: row;

    .container_groupA {
      flex: 0.75;
      margin-right: 2rem;
    }

    .container_groupB {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(2, 1fr);


      grid-gap: 2rem;

      .container_groupC {
        display: none;
      }
    }
   

  }

  

}









@media screen and (max-width: 990px) {
  
  .gpt3__blog {

    .heading {
      width: 100%;
      text-align: left;
      margin-bottom: 5rem;
  
      h1 {
        font-size: 45px;
        line-height: 70px;
        font-family: var(--font-family);
        font-weight: 700;
        color: rgba(200, 215, 243, 0.8);
      }
  
      span {
        background: var(--gradient-text);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
  
    }

    

    .container {
      

      .container_groupA {
        display: none;
    
      }
      
    
    

      .container_groupB {
        
      .container_groupC {
        display: flex;
        flex:0.25
      
      }
       
      }
    }
    
  }

  

 
}

@media screen and (max-width: 800px) {
  .gpt3__blog {

    .heading {
      width: 100%;
      text-align: left;
      margin-bottom: 5rem;
  
      h1 {
        font-size: 40px;
        line-height: 65px;
        font-family: var(--font-family);
        font-weight: 700;
        color: rgba(200, 215, 243, 0.8);
      }
  
      span {
        background: var(--gradient-text);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
  
    }
  }

}

@media screen and (max-width: 700px) {
  .gpt3__blog {

    .heading {
      width: 90%;
      align-self: center;
      text-align: left;
      margin-bottom: 5rem;
      
  
      h1 {
        font-size: 35px;
        line-height: 60px;
        font-family: var(--font-family);
        font-weight: 700;
        color: rgba(200, 215, 243, 0.8);
      }
  
      span {
        background: var(--gradient-text);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
  
    }
  }

}

@media screen and (max-width: 650px) {
  .gpt3__blog {
  
    padding: 0;
    .container {
      display: flex;
      grid-template-columns: none;
      width: 100%;
      justify-content: center;
      

      .container_groupA {
        display: none;
    
      }
      
    
    

      .container_groupB {
        display: flex;
        grid-template-columns: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        grid-gap: 0;
        
      .container_groupC {
        width: 100%;
        display: flex;
        justify-content: center;
        
      }
       
      }
    }
    
  }
}


@media screen and (max-width: 500px) {
  .gpt3__blog {

    .heading {
      width: 90%;
      align-self: center;
      text-align: left;
      margin-bottom: 5rem;
      
  
      h1 {
        font-size: 30px;
        line-height: 50px;
        font-family: var(--font-family);
        font-weight: 700;
        color: rgba(200, 215, 243, 0.8);
      }
  
    
  
    }

    .container {
    

      .container_groupA {
        display: none;
    
      }
      
    
    

      .container_groupB {
        
      .container_groupC {
        display: flex;
        flex:0.25
      
      }
       
      }
    }
    
  }
}

@media screen and (max-width: 400px) {
  .gpt3__blog {

    .heading {
      
  
      h1 {
        font-size: 25px;
        line-height: 45px;
        font-family: var(--font-family);
        font-weight: 700;
        color: rgba(200, 215, 243, 0.8);
      }
  
    
  
    }
  }
}