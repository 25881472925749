.testimonial {
    flex: 1;
    width: 100%;
    flex-direction: column;
    background-image: url(../../assets/back22.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    background-color: transparent;
    font-family: var(--font-family);


    height: 60rem;
    display: flex;

    justify-content: center;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;



        .testimonial-item {
            width: 70%;
            min-height: 400px;
            border: solid 3px rgba(255, 255, 255, 0.02);
            background-color: rgba(106, 120, 151, 0.116);
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            box-shadow: 2px 0px 10px rgba(113, 148, 187, 0.219);
            color: rgba(150, 150, 150, 0.8);
            display: flex;
            justify-content: space-around;

            align-items: center;

            flex-direction: row;

            border-radius: 15px;

            transition: all 0.3s ease-in-out;

            img {
                width: 200px;
                height: auto;
                object-fit: cover;
                border-radius: 50%;
                margin-left: 2rem;

            }

            @media screen and (min-width: 2000px) {
                min-height: 450px;

                img {
                    width: 150px;
                    height: 150px;
                }
            }





            .content {
                width: 60%;
                height: 100%;
                text-align: left;
                display: flex;
                flex-direction: column;

                justify-content: space-around;
                align-items: flex-start;
                align-content: stretch;
                margin: 0;
                padding: 0;


                .testimonial-text {
                    display: flex;
                    height: 65%;
                    align-items: center;

                    p {
                        background: var(--gradient-text);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-size: 1.2rem;
                        line-height: 2rem;
                        text-align:justify;
                        font-weight: 600;
                    }
                }

                .personal-data {
                    display: flex;
                    height: 30%;
                  
                    
                    align-self: flex-end;
                    flex-direction: column;
                    align-items: flex-end;
                    align-content: stretch;
                    
                  
                  


                    h4 {
                        font-weight: 600;
                        color: rgba(108, 185, 248, 0.8);
                        margin-top: 2rem;
                    }

                    h5 {
                        font-weight: 400;
                        color: var(--gray-color);
                        margin-top: 5px;
                    }

                    h5:last-child {
                        font-weight: 800;
                        color: rgba(255, 134, 64, 0.849);
                        margin-top: 5px;
                    }

                }






            }
        }

        .app__testimonial-btns {
            flex-direction: row;
            margin-top: 1rem;
            display: flex;

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 45px;
                height: 45px;
                border-radius: 50%;
                border: solid 2px rgba(12, 30, 68, 0.418);
                /* ff 3.6+ */
                background: -moz-radial-gradient(circle at 50% 50%, rgba(15, 40, 113, 0.32) 0%, rgba(0, 6, 73, 0.2) 100%);

                /* safari 5.1+,chrome 10+ */
                background: -webkit-radial-gradient(circle at 50% 50%, rgba(15, 40, 113, 0.32) 0%, rgba(0, 6, 73, 0.2) 100%);

                /* opera 11.10+ */
                background: -o-radial-gradient(circle at 50% 50%, rgba(15, 40, 113, 0.32) 0%, rgba(0, 6, 73, 0.2) 100%);

                /* ie 10+ */
                background: -ms-radial-gradient(circle at 50% 50%, rgba(15, 40, 113, 0.32) 0%, rgba(0, 6, 73, 0.2) 100%);

                /* global 92%+ browsers support */
                background: radial-gradient(circle at 50% 50%, rgba(15, 40, 113, 0.32) 0%, rgba(0, 6, 73, 0.2) 100%);

                margin: 1rem;
                transition: all 0.3s ease-in-out;

                svg {
                    width: 20px;
                    height: 20px;
                    color: rgba(229, 247, 233, 0.8);

                }

                &:hover {
                    background-color: rgba(9, 78, 206, 0.308);
                    box-shadow: 0 0 2px rgba(119, 119, 119, 0.781);

                    svg {
                        color: rgba(238, 166, 79, 0.8);
                    }

                }
            }
        }

    }



}

@media screen and (max-width: 1200px) {
    .testimonial {


        .container {


            .testimonial-item {
            

                .content {

                    .testimonial-text {

                        p {
                            font-size: 1.1rem;
                            line-height: 2rem;
                           
                        }

                    }





                  


                }
            }

        }
    }



}




@media screen and (max-width: 910px) {
    .testimonial {


        .container {


            .testimonial-item {
                

                img {
                    width: 190px;
                    height: 190px;
                    margin-left: 1rem;
                }

                .content {

                    .testimonial-text {

                        p {
                            font-size: 1rem;
                            line-height: 1.8rem;
                           
                        }

                    }

                  
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .testimonial {


        .container {


            .testimonial-item {
                width: 80%;
                flex-direction: column;
                min-height: 36rem;

                img {
                    width: 190px;
                    height: 190px;
                    margin: 2rem 0;

                }

                .content {
                    width:90%;

                    .testimonial-text {

                        p {
                            font-size: 1rem;
                            line-height: 1.8rem;
                           
                        }
                        

                    }

                    margin-bottom: 2rem;

                }
            }

        }
    }



}

@media screen and (max-width: 600px) {
    .testimonial {
        background-size: cover;
        .container {
            .testimonial-item {
                min-height: 37rem;

             

                img {
                }

                .content {


                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .testimonial {
    
        .container {
            .testimonial-item {
                min-height: 41rem;

             

                img {
                }

                .content {


                }
            }
        }
    }
}

