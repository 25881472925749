.gpt3__navbar {
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 100%;
    max-width: 100%;
    padding: 2rem 3rem;

    @media screen and (max-width: 700px) {
        .gpt3__navbar {
            padding: 2rem 4rem;
        }
    
    
    }

    .logo {
        transition: all 0.4s ease-in-out;
        border-radius: 3%;
        margin-right: 2rem;
        background-color: rgba(17, 26, 100, 0.021);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

        img {
            width: 150px;
        }

        &:hover {
            box-shadow: 0 0 5px -10px rgba(255, 255, 255, 0.2);
            background-color: rgba(0, 3, 12, 0.5) !important;
        }



    }

    .links {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;

        ul {
            width: 100%;
            flex-direction: row;
            display: flex;
            list-style: none;
            justify-content: space-evenly;

            li {
                text-transform: uppercase;
                margin: 1rem;
                color: rgba(108, 123, 129, 0.705);
                font-weight: bold;
                text-shadow: 0 0 2px rgba(122, 121, 121, 0.52);
                font-family: var(--font-family);
                flex-direction: row;

                &:hover {
                    a {
                        color: #0004f8c2;
                        
                        text-decoration: !important;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .menu {
        display: flex;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
  
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(21, 137, 173, 0.158);
    box-shadow: 0 0 15px #f5f4f41a;

        svg {
        cursor: pointer;
        color:aqua;
        width: 70%;
      height: 70%;
     

        }
        .menu_container {
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            
            
        
            padding: 1rem;
        
            width: 100%;
            height: 100vh;
            
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            flex-direction: column;
            
         
            background-color: rgba(0, 0, 0, 0.801);
            
          
            /* top box shadow */
            box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);
           
           
            .menu_container-links{

                margin-top: 5rem;
                margin-right: 1rem;
                ul {
                    margin-top: 5rem;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                  
                    width: 100%;
                    height: 100%;
                  
              
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    flex-direction: column;
              
                    li {
                        width: 100%;
                        justify-content: flex-end;
                        text-align: end;
                      margin: 1rem;
                      text-transform: uppercase;
                    margin: 1rem;
                    color: rgba(108, 123, 129, 0.705);
                    font-weight: bold;
                    text-shadow: 0 0 2px rgba(122, 121, 121, 0.52);
                    font-family: var(--font-family);
                    flex-direction: row;
    
                    
    
                    &:hover {
                        a {
                            color: #0004f8c2;
                            
                            text-decoration: !important;
                            font-weight: bold;
                        }
                    }
              
                     
                      
                    }
                  }
                
            }
    
    
    
            p {
                margin: 1rem 0;
            }

            svg {
                width: 35px;
                height: 35px;
                color: var(--secondary-color);
                margin: 0.5rem 1rem;
              }

           
    
       
        }
        
    }



    &:hover {
        /* ff 3.6+ */
        background: -moz-linear-gradient(90deg, rgba(0, 20, 70, 1) 0%, rgba(0, 210, 255, 1) 100%);

        /* safari 5.1+,chrome 10+ */
        background: -webkit-linear-gradient(90deg, rgba(0, 20, 70, 1) 0%, rgba(0, 210, 255, 1) 100%);

        /* opera 11.10+ */
        background: -o-linear-gradient(90deg, rgba(0, 20, 70, 1) 0%, rgba(0, 210, 255, 1) 100%);

        /* ie 6-9 */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00D2FF', endColorstr='#001446', GradientType=0);

        /* ie 10+ */
        background: -ms-linear-gradient(90deg, rgba(0, 20, 70, 1) 0%, rgba(0, 208, 255, 0.281) 100%);

        /* global 94%+ browsers support */
        background: linear-gradient(90deg, rgba(0, 20, 70, 0.21) 0%, rgba(0, 208, 255, 0.137) 100%);

        transition: all 0.3s ease-in-out;
        box-shadow: 0px 2px 4px 0px rgba(31, 31, 31, 0.274);


        .logo {
            transition: all 0.4s ease-in-out;
            background-color: rgba(19, 19, 19, 0.116);
            box-shadow: 0px 0px 10px rgba(216, 216, 216, 0.199);
        }

        a {
            text-transform: uppercase;
            color: rgba(19, 115, 224, 0.699);
            font-weight: bold;
            text-shadow: 0 0 2px rgba(28, 29, 32, 0.678);


        }









    }
    @media screen and (min-width: 900px) {
       
        .menu {
            display:none;
        }
     
    }

    @media screen and (max-width: 900px) {
        .links {
            display: none;
        }
        .menu {
            display: flex;
        }
     
    }
}

